$search-header-height: 45px;
$app-header-height: 64px;
$buffer: 1px;

// margins
$standard-margin: 16px;
$standard-gap: 16px;
$better-header-height: 20px;
$table-margin: $standard-margin;
$nav-view-box-margin: 24px;
$breadcrumb-height: 38px;

// view box with app header and nav accounted for
$view-box-height: calc(100vh - $app-header-height - #{$nav-view-box-margin * 2} - $buffer);
$view-box-width: calc(100% - #{$nav-view-box-margin * 2});
