/* You can add global styles to this file, and also import other style files */
/* stylelint-disable scss/at-rule-no-unknown */
@use 'sass:map';
@use '@angular/material' as mat;
@import './theme/m3-theme.scss';
@include mat.elevation-classes();
@include mat.app-background();
@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import './theme/default';
@import './theme/structure';
@import './theme/custom-theme.scss';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

:root {
  // Need important to override ionic
  /* stylelint-disable declaration-no-important */
  --ion-background-color: var(--white) !important;
  --ion-tab-bar-background: var(--white) !important;
  --ion-item-background: var(--white) !important;
  --ion-overlay-background-color: var(--white) !important;
  --ion-color-step-100: var(--white);
  --ion-background-color-step-100: var(--green-success);
  --ion-color-step-150: var(--mobile-background-color);
  --ion-background-color-step-150: var(--yellow-bright);
  --ion-color-step-250: var(--mobile-radio-color) --ion-background-color-step-250:
    var(--terlumina-orange);
  --ion-item-border-color: var(--default-border-color);
}

ion-input.custom.ios .input-wrapper .native-wrapper {
  border: 1px solid var(--mobile-border-color);
  border-radius: 4px;
  text-align: left;
  padding-left: 8px;
  height: 56px;
}

ion-input.custom.ios.has-focus .input-wrapper .native-wrapper {
  border-color: var(--mobile-focus-color);
}

ion-input.custom.ios.invalid-field .input-wrapper .native-wrapper {
  border-color: var(--color-scale-red);
}

ion-input.custom.ios .input-wrapper .label-text-wrapper {
  transform: translateY(35px) translateX(6px) scale(1);
  padding-left: 6px;
}

ion-input.custom.ios.has-focus .input-wrapper .label-text-wrapper,
ion-input.custom.ios.has-value .input-wrapper .label-text-wrapper {
  transform: translateY(63%) scale(0.75) translateX(10px);
  background: var(--white);
  padding: 0 2px;
}

ion-input.custom.ios .input-bottom .helper-text {
  color: var(--color-scale-red);
}

ion-input.custom.ios .input-bottom {
  border: none;
  padding-left: 16px;
  padding-top: 0px;
}

ion-button {
  height: 48px;
  --border-radius: 6px;
}

:root {
  .picker-before {
    height: 40px;
  }
}

.alert-radio-group.sc-ion-alert-ios {
  border-top: 1px solid var(--default-border-color);
  border-bottom: 1px solid var(--default-border-color);
}

:host {
  --border-color: var(--mobile-dark-red);
  --checkbox-background-checked: var(--mobile-dark-red);
  --border-color-checked: var(--mobile-dark-red);
}

@mixin btn(
  $color,
  $bg-color,
  $border-color,
  $hover-color,
  $hover-bg-color,
  $hover-border-color,
  $disabled-color,
  $disabled-bg-color,
  $disabled-border-color,
  $active-color,
  $active-bg-color,
  $active-border-color
) {
  background-color: #{$bg-color} !important;
  color: #{$color} !important;
  .mdc-button__label {
    color: #{$color};
  }
  .mat-button-focus-overlay {
    background-color: transparent !important;
  }
  border: 1px solid #{$border-color} !important;
  padding: 0.5rem 1.25rem;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #{$hover-bg-color} !important;
    color: #{$hover-color} !important;
    border: 1px solid #{$hover-border-color} !important;
    .mdc-button__label {
      color: #{$hover-color} !important;
    }
    &.link-button {
      text-decoration: underline;
    }
  }
  &:active {
    background-color: #{$active-bg-color} !important;
    color: #{$active-color} !important;
    border: 1px solid #{$active-border-color} !important;
    .mdc-button__label {
      color: #{$active-color} !important;
    }
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #{$disabled-bg-color} !important;
    color: #{$disabled-color} !important;
    border: 1px solid #{$disabled-border-color} !important;
    .mdc-button__label {
      color: #{$disabled-color} !important;
    }
  }
}

// Angular Material Typography
/* Styles to be applied to buttons */
$my-custom-button: mat.m2-define-typography-level(
  $font-family: var(--default-font),
  $font-weight: 500,
  $font-size: 2rem,
  $line-height: 1,
  $letter-spacing: 0px,
);

/* Styles to be applied to input-fields */
$my-custom-input: mat.m2-define-typography-level(
  $font-family: var(--default-font),
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: 0px,
);

/* Merge custom configs into existing config */
$my-typography-config: map.merge(
  mat.m2-define-typography-config(
    /* 'button'-property will work out of the box */ $button: $my-custom-button,
    $font-family: '"Inter", sans-serif'
  ),
  (
    /* 'input'-property will have to be assigned under '@mixin typography' further below */ 'input':
      $my-custom-input
  )
);

/* Apply custom config */
@include mat.all-component-typographies($my-typography-config);

/* Let's assign the custom property 'input' that we defined above */
@mixin typography($theme) {
  $custom-typography-config: mat.m2-get-typography-config($theme);

  .mat-mdc-form-field {
    @include mat.m2-typography-level($custom-typography-config, 'input');
  }
}

/* Define custom app-theme based on custom-configs */
$app-theme: mat.m2-define-light-theme(
  (
    typography: $my-typography-config,
  )
);

/* Apply custom app-theme */
@include typography($app-theme);
// End of Angular Material Typography

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

* {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  letter-spacing: 0px;
}

body {
  margin: 0;
  height: inherit;
  font-size: 16px;
  --ion-text-color: var(--default-text-color);
}

html {
  height: 100%;
}

label,
input.form-control,
.input-error {
  font-size: 0.875rem;
}

label {
  display: block;
  line-height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.form-control-wrapper {
  margin-bottom: 1.5rem;

  label {
    margin-bottom: 0.5rem;
  }

  &.no-mb {
    margin-bottom: 0;
  }

  &.invalid-field {
    margin-bottom: 1rem;

    input.form-control {
      border: 1px solid var(--red-bright);
      margin-bottom: 0.5rem;
    }
  }
}

input.form-control {
  box-sizing: border-box;
  border-radius: var(--default-radius);
  border: 1px solid var(--default-border-color);
  line-height: 2.625rem;
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  height: 2.625rem;
  &.no-border {
    border: none;
  }
  &.disabled {
    background-color: var(--white);
    border: 1px solid var(--gray-200);
  }
}

.settings-background {
  background-color: var(--gray-150);
  width: 700px;
  border: 1px solid var(--gray-200);
  border-radius: var(--default-radius);
  margin-bottom: 10px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.input-errors {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}

.input-error {
  color: var(--red-bright);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}

.error-container,
.success-container {
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  justify-content: flex-start;
  align-items: center;
}

.error-container {
  color: var(--red-bright);
}

.success-container {
  color: var(--default-primary);
}

.error-container .error,
.success-container .success {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  margin-left: 0.5rem;
}

.primary-button {
  @include btn(
    var(--white),
    var(--default-primary),
    var(--default-primary),
    var(--white),
    var(--default-primary-hover-bg),
    var(--default-primary-hover-bg),
    var(--default-gray),
    var(--gray-175),
    var(--gray-175),
    var(--white),
    var(--default-primary-hover-bg),
    var(--default-primary-hover-bg)
  );
  &.disabled {
    cursor: not-allowed;
    background-color: var(--default-disabled) !important;
    pointer-events: none;
  }
}

.secondary-button {
  @include btn(
    var(--default-primary),
    var(--white),
    var(--default-primary),
    var(--default-primary),
    var(--default-secondary-hover-bg),
    var(--default-primary),
    var(--default-gray),
    var(--gray-175),
    var(--gray-175),
    var(--default-primary),
    var(--default-secondary-active-bg),
    var(--default-primary)
  );
}

.tertiary-button {
  @include btn(
    var(--default-primary),
    var(--white),
    var(--white),
    var(--white),
    var(--default-primary),
    var(--default-primary),
    var(--default-gray),
    var(--gray-175),
    var(--gray-175),
    var(--default-primary),
    var(--default-secondary-hover-bg),
    var(--default-secondary-hover-bg)
  );
}

.warn-tertiary-button {
  @include btn(
    var(--red-bright),
    var(--white),
    var(--white),
    var(--white),
    var(--red-bright),
    var(--red-bright),
    var(--default-gray),
    var(--gray-175),
    var(--gray-175),
    var(--red-bright),
    var(--white),
    var(--white)
  );
}

.clear-button {
  @include btn(
    var(--default-primary),
    transparent,
    transparent,
    var(--default-primary-hover-bg),
    transparent,
    transparent,
    var(--default-gray),
    transparent,
    transparent,
    var(--default-primary),
    transparent,
    transparent
  );
}

.clear-black-button {
  @include btn(
    var(--black),
    transparent,
    transparent,
    var(--default-primary-hover-bg),
    transparent,
    transparent,
    var(--default-gray),
    transparent,
    transparent,
    var(--black),
    transparent,
    transparent
  );
}

.clear-primary-button {
  @include btn(
    var(--default-primary),
    transparent,
    transparent,
    var(--default-primary-hover-bg),
    transparent,
    transparent,
    var(--default-gray),
    transparent,
    transparent,
    var(--default-primary),
    transparent,
    transparent
  );
  &.disabled {
    pointer-events: all !important;
    background-color: transparent !important;
  }
}

.link-button {
  @include btn(
    var(--default-primary),
    transparent,
    transparent,
    var(--default-primary-hover-bg),
    transparent,
    transparent,
    var(--default-gray),
    transparent,
    transparent,
    var(--default-primary),
    transparent,
    transparent
  );
}

.clear-danger-button {
  @include btn(
    var(--red-bright),
    transparent,
    transparent,
    var(--red-bright),
    transparent,
    transparent,
    var(--default-gray),
    transparent,
    transparent,
    var(--red-bright),
    transparent,
    transparent
  );
}

.clear-gray-button {
  @include btn(
    var(--default-gray),
    transparent,
    transparent,
    var(--gray-800),
    transparent,
    transparent,
    var(--gray-800),
    transparent,
    transparent,
    var(--default-gray),
    transparent,
    transparent
  );
}

.clear-dark-gray-button {
  @include btn(
    var(--gray-650),
    transparent,
    transparent,
    var(--gray-800),
    transparent,
    transparent,
    var(--gray-800),
    transparent,
    transparent,
    var(--gray-650),
    transparent,
    transparent
  );
}

.success-button {
  @include btn(
    var(--white),
    var(--default-primary),
    var(--default-primary),
    var(--white),
    var(--green-success),
    var(--green-success),
    var(--default-gray),
    var(--gray-175),
    var(--gray-175),
    var(--white),
    var(--default-primary),
    var(--default-primary)
  );
}

.sso-button {
  @include btn(
    var(--white),
    var(--default-primary),
    var(--default-primary),
    var(--white),
    var(--default-primary-hover-bg),
    var(--default-primary-hover-bg),
    var(--default-gray),
    var(--gray-175),
    var(--gray-175),
    var(--white),
    var(--default-primary-hover-bg),
    var(--default-primary-hover-bg)
  );
}

.sso-icon-button {
  @include btn(
    var(--default-primary),
    var(--white),
    var(--default-primary),
    var(--white),
    var(--default-primary),
    var(--default-primary),
    var(--default-gray),
    var(--gray-175),
    var(--gray-175),
    var(--white),
    var(--default-primary),
    var(--default-primary)
  );
}

.cancel-button {
  @include btn(
    var(--default-primary),
    var(--white),
    var(--default-primary),
    var(--black),
    var(--default-secondary-hover-bg),
    var(--default-primary),
    var(--default-gray),
    var(--gray-175),
    var(--gray-175),
    var(--black),
    var(--default-secondary-active-bg),
    var(--default-primary)
  );
}

.danger-button {
  @include btn(
    var(--white),
    var(--red-bright),
    var(--red-bright),
    var(--white),
    var(--red-bright),
    var(--red-bright),
    var(--default-gray),
    var(--gray-175),
    var(--gray-175),
    var(--white),
    var(--red-bright),
    var(--red-bright)
  );
}

.danger-secondary-button {
  @include btn(
    var(--red-bright),
    var(--white),
    var(--red-bright),
    var(--white),
    var(--red-bright),
    var(--red-bright),
    var(--default-gray),
    var(--gray-175),
    var(--gray-175),
    var(--red-bright),
    var(--white),
    var(--red-bright)
  );
}

.danger-icon-button {
  @include btn(
    var(--red-bright),
    var(--white),
    var(--red-bright),
    var(--white),
    var(--red-bright),
    var(--red-bright),
    var(--default-gray),
    var(--gray-175),
    var(--gray-175),
    var(--white),
    var(--red-bright),
    var(--red-bright)
  );
}

.themed-btn-primary {
  background-color: var(--green-success);
  width: 100%;

  &:hover {
    background-color: var(--green-success);
  }
}

.icon-button {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-message {
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  .status-icon {
    font-size: 1.25rem;
    margin-right: 0.5rem;
  }

  .message-header {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.375rem;
    display: flex;
  }

  .message-body {
    margin-top: 0.875rem;
    margin-left: 2.25rem;
  }

  .message-body ul {
    list-style-type: disc;
    padding-left: 1.125rem;
  }

  &.warning {
    background-color: var(--yellow-shade);
    color: var(--yellow-dark);
  }
}

.app-dialog-content {
  .mat-mdc-dialog-actions {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .mdc-dialog .mdc-dialog__content {
      padding: 1.5rem 1rem 1.5rem 1rem;
      color: var(--black);
    }

    button + button,
    button + app-new-action-button {
      margin-left: 8px;
    }

    .mdc-button__label {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

.mdc-dialog__surface {
  overflow-x: hidden;
  overflow-y: auto;
}

[mat-dialog-content] {
  padding: 24px;
}

.mat-mdc-dialog-title {
  background-color: var(--default-background-color);
  color: var(--white);
}

.dialog-actions {
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  button + button,
  button + app-new-action-button {
    margin-left: 8px;
  }

  &.full-size {
    width: 100%;
    padding: 0;
  }
}

.dialog-actions.absolute {
  position: aboslute;
  margin-left: 64%;
}

.content-body {
  margin-bottom: 0.5rem;
  font: 400 0.875rem/1.25rem var(--default-font);

  ul {
    list-style-type: disc;
    padding: 0 0 0 1rem;
  }

  &.scoped {
    padding: 1rem;
    border-radius: 0.375rem;
  }

  &.danger {
    background-color: var(--red-50);
    color: var(--red-bright);
  }

  &.warning {
    background-color: var(--yellow-shade);
    color: var(--yellow-dark);
  }

  .scope-header {
    display: flex;
    align-items: center;
    font-weight: 700;
    margin-bottom: 0.25rem;

    .scope-icon {
      font-size: 1rem;
      margin-right: 5px;
    }
  }

  .scope-content {
    padding-left: 1.25rem;
  }
}

.back-color {
  background-color: var(--default-background-color);
}

.table-loading {
  .skeleton-loader.progress {
    height: 54px;
    margin-bottom: 0px;
  }
}

.mat-mdc-snack-bar-container {
  .mat-mdc-snack-bar-label {
    padding: 0px;
  }

  .ng-star-inserted {
    display: flex;
    align-items: center;
    padding: 16px 18px;
  }

  .snack-bar-icon-wrapper {
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .snack-bar-icon-wrapper.info {
    background-color: var(--default-primary);
  }

  .snack-bar-icon-wrapper.danger {
    background-color: var(--red-bright);
  }

  .custom-snackbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .snackbar-icon-and-message {
    display: flex;
  }

  .ng-star-inserted .snackbar-error-header,
  .ng-star-inserted .snackbar-success-header,
  .ng-star-inserted .snackbar-info-header,
  .ng-star-inserted .snackbar-warning-header {
    font-weight: bold;
    font-size: 14px;
    margin: 0px;
    display: flex;
    align-items: center;
    text-align: left;
    height: 48px;
    margin-right: 8px;
  }

  .ng-star-inserted .snackbar-error-body,
  .ng-star-inserted .snackbar-success-body,
  .ng-star-inserted .snackbar-info-body,
  .ng-star-inserted .snackbar-warning-body {
    font-size: 16px;
    color: var(--white);
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  min-width: 429px !important;
}

.snack-bar-action-text {
  color: var(--white);
  vertical-align: middle;
  cursor: pointer;
}

.snack-bar-link {
  color: var(--default-primary);
  vertical-align: middle;
  cursor: pointer;
}

.transparent {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: transparent;
    box-shadow: none;
  }
  .mdc-circular-progress__circle-clipper,
  .mdc-circular-progress__gap-patch {
    svg {
      stroke: var(--red-bright);
    }
  }
}

.table-header {
  height: $search-header-height;
  // box-shadow: 0px 4px 6px 0px var(--gray-200);
}

.table-header th {
  height: 100%;
}

table {
  width: 100%;
}

.snackbar-error {
  text-align: center;
  margin: 0;
  margin-top: 5px;
}

.table-header h2 {
  margin: 0px;
}

.table-header .left-title {
  display: flex;
  align-items: center;
}

.table-header .right-content {
  align-items: right;
  text-align: right;
  position: relative;
}

.table-header .right-content div {
  display: inline-flex;
  align-items: center;
}

app-search-header input {
  padding-left: 30px;
  color: var(--gray-500);
  font-weight: normal;
}

app-search-header input:focus {
  outline: none;
  border: none;
}

app-debounce-input input {
  padding-left: 30px;
  color: var(--gray-500);
  font-weight: normal;
  outline: none;
  border: none;
}

app-debounce-input input:focus {
  outline: none;
  border: none;
}

.mdc-dialog__title {
  padding: 0 24px 12px;

  &.flex {
    padding: 12px 24px;
  }
}

.mat-mdc-table {
  box-shadow: none;
}

.list-table-settings {
  padding: 17.5px;
  background-color: var(--white);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  user-select: none;
  position: relative;
}

.list-table-settings tbody {
  border-radius: 5px;
  border: 1px solid var(--gray-100);
}

.cursor-edit {
  cursor:
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAQAAAD8x0bcAAAAWklEQVQoz83KsQ2DMBAAwOszhFkIGobwOAzAMJYYhEGwxKdKydMQiauP/5usPnkZdWHL2qDYhbBeldmhKnbddFW6cKqKMSshHIa70s3vL7T78ktpYdG0vDzjC7R1PK5Pf85UAAAAAElFTkSuQmCC')
      0 16,
    auto;
}

.bold {
  font-weight: bold;
}

.mdc-data-table__cell {
  font-size: 14px;
  max-height: 52px;
  overflow: hidden;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 8px;
}

.cross {
  padding: 5px;
  cursor: pointer;
}

input.ng-invalid.ng-dirty,
.invalid-field input.ng-dirty {
  padding-right: 1.7rem;
  border: 1px solid var(--red-bright);
  background-repeat: no-repeat;
  background-position: right center;
  background-origin: padding-box;
  background-size: calc(1.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.mdc-switch.mdc-switch--selected:disabled .mdc-switch__handle::after {
  background: var(--white);
  box-shadow: 0px 2px 3px 0px var(--black);
}

h3 {
  font-weight: 700;
  margin: 0;
  margin-left: 8px;
}

.dialog-wrapper {
  overflow: hidden;
  border-top: 1px solid var(--default-border-color);
  border-bottom: 1px solid var(--default-border-color);
}

.mat-mdc-dialog-container .mdc-dialog__content {
  padding: 1.5rem 1rem 1.5rem 1rem;
  color: var(--black);
}
.mat-mdc-dialog-container .mdc-dialog__content,
form,
.mdc-button .mdc-button__label {
  font-size: 14px;
  font-style: normal;
}

.mat-mdc-button .mat-mdc-button-touch-target {
  width: 65px;
  height: 34px;
}

ng-scrollbar {
  --scrollbar-size: 10px;
  --scrollbar-track-color: var(--gray-50);
  --scrollbar-thumb-color: var(--gray-300);
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: var(--tooltip-background-color);
  color: var(--white);
  font-size: 12px;
  padding: 5px 14px;
}

/* Global styling for ngx-drop-zone
  https://github.com/georgipeltekov/ngx-file-drop/issues/21#issuecomment-347495083
*/

.drop-zone {
  height: 100px;

  // Best solution I could find, can't control dashed border spacing
  background:
    linear-gradient(to right, transparent 40%, var(--default-primary) 0%) top repeat-x,
    linear-gradient(transparent 40%, var(--default-primary) 0%) right repeat-y,
    linear-gradient(to right, transparent 40%, var(--default-primary) 0%) bottom repeat-x,
    linear-gradient(transparent 40%, var(--default-primary) 0%) left repeat-y;
  background-size:
    12px 2px,
    2px 12px;
  border-radius: var(--default-radius);
}
.drop-zone-content {
  height: 100%;
  width: 100%;
}

.form-control.disabled,
.disabled .form-control,
input[disabled] {
  color: var(--gray-400);
  border: 1px solid var(--gray-400);
  background-color: transparent;
  font-style: oblique 10deg;
  user-select: none;
  pointer-events: none;

  &::selection {
    background-color: transparent;
  }
}

.form-wrapper {
  color: var(--black);
}

.form-wrapper > input {
  padding-block: 8px;
  border: 1px solid var(--gray-950);
  border-radius: var(--default-radius);
  padding-left: 20px;
  margin: 10px 0px;
  height: 50px;
}

.form-wrapper > input:focus-visible {
  outline: none;
}

.disabled-row {
  color: var(--gray-200);
}

.mat-mdc-paginator-outer-container {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: -4px;
    min-height: 30px;
  }
}

.mat-mdc-paginator-touch-target {
  height: 30px !important;
  top: 37% !important;
}

div.mat-mdc-select-panel {
  padding: 0;
  border-radius: 10px;
  .mdc-list-item.mdc-list-item--selected {
    .mdc-list-item__primary-text {
      color: var(--black);
    }
  }
}

.inline-timer {
  .owl-dt-timer {
    padding: 0;
    height: auto;
  }

  .owl-dt-inline-container {
    width: fit-content;
  }

  .owl-dt-timer-box {
    width: fit-content;
  }

  owl-date-time-timer-box {
    .owl-dt-control-button {
      display: none;
    }
  }

  owl-date-time-timer-box:first-child {
    margin-right: 5px;
  }

  owl-date-time-timer-box:nth-child(2) {
    margin-left: 5px;
  }

  .owl-dt-timer-divider {
    font-size: 10px;
    width: auto;
    left: -0.5em;
  }

  owl-date-time-container {
    box-shadow: none;
    background: transparent;
  }

  .owl-dt-timer-hour12 .owl-dt-timer-hour12-box {
    visibility: visible;
    height: 40px;
    width: 40px;
    border-radius: var(--default-radius);
    border: 1px solid var(--blue-bright);
    overflow: hidden;
    background: var(--time-select-am-bg);

    &:hover .owl-dt-control-button-content {
      background-color: var(--blue-bright);
    }
  }

  .owl-dt-timer-hour12 {
    margin-left: 8px;
    align-items: normal;
    color: var(--blue-bright);
  }

  .owl-dt-timer-content {
    flex: none;
    margin: 0;
  }

  .owl-dt-timer-content .owl-dt-timer-input {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: var(--default-radius);
    border: 1px solid var(--gray-950);
    color: var(--gray-950);
    font-family: var(--default-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

:root .mat-mdc-dialog-content {
  max-height: unset;
}

.mat-mdc-slide-toggle.mat-accent {
  font-size: 20px;
}

.mat-mdc-tab .mdc-tab__text-label {
  font-family: var(--default-font);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  font-kerning: normal;
}

.mat-mdc-tab-label-container {
  border-top-left-radius: var(--default-radius);
  border-top-right-radius: var(--default-radius);
}

.mat-mdc-tab-header {
  border-top-left-radius: var(--default-radius);
  border-top-right-radius: var(--default-radius);
  border-bottom: 1px solid var(--default-border-color);
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important; // Need important to override material
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: var(--default-radius);
  border-bottom-left-radius: var(--default-radius);
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: var(--default-radius);
  border-bottom-right-radius: var(--default-radius);
}

.mat-mdc-tab-header {
  background-color: var(--white);
  position: sticky;
  top: 0;
  z-index: 9;
  flex: none;
}

.mat-mdc-tab-body-wrapper {
  flex: 1 1 100%;
  overflow-y: auto;
  border-bottom-left-radius: var(--default-radius);
  border-bottom-right-radius: var(--default-radius);
}

.mat-mdc-tab.mdc-tab--active {
  background-color: var(--gray-100);
}

.mat-mdc-tab .mdc-tab__ripple,
.mat-mdc-tab-ripple.mat-ripple {
  display: none;
}

.stop-text-select {
  user-select: none;
}

.scrollbar-hover {
  overflow-y: scroll;
  overflow-x: hidden;

  overflow: hidden;
  padding-right: 5px;
  overflow-y: scroll;

  mask-image: linear-gradient(to top, transparent, var(--black)),
    linear-gradient(to left, transparent 17px, var(--black) 17px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, var(--black)),
    linear-gradient(to left, transparent 17px, var(--black) 17px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition:
    mask-position 0.3s,
    -webkit-mask-position 0.3s;
  animation: background 5s infinite alternate;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: var(--gray-50);
  border: 1px solid var(--gray-100);
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background: var(--gray-300);
  border-radius: 15px;
}

.form-item .child-items {
  padding-left: 2rem;
}

app-task-item {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.link-text {
  color: var(--default-primary);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.color-pill--global {
  border-radius: 20px;
  padding: 2px 10px;
  color: var(--white);
  font-size: 12px;
  font-weight: 200;
  margin-left: 10px;
  &.red--global {
    background-color: var(--red-bright);
  }
  &.green--global {
    background-color: var(--green-bright);
  }
  &.yellow--global {
    background-color: var(--yellow-bright);
  }
}

.task-form {
  .task-item-label {
    display: flex;
    font: 500 0.875rem/1.625rem var(--default-font);

    a {
      color: var(--blue-bright);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .task-item-wrapper {
    display: block;
    box-sizing: border-box;

    &.assign-task,
    &.reassign {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .task-item-label {
        flex: none;
      }
    }
  }

  .task-item {
    width: 100%;
  }

  app-date-picker {
    max-width: 11.25rem;
  }

  app-file-attachment {
    max-width: 31rem;
  }

  app-text-box {
    max-width: 18.375rem;
  }

  .task-item-order {
    flex: none;
    margin-right: 1rem;
    font: 500 1rem/1.5rem var(--default-font);
    padding: 0 0.5rem;
    border: 0.0625rem solid var(--gray-300);
    border-radius: 0.375rem;
    display: inline-block;
    margin-top: 0px;
  }

  .task-item > *:not(.task-item-order) {
    flex: 1 1 100%;
  }

  input[type='checkbox'] {
    appearance: none;
    width: 1.125rem;
    height: 1.125rem;
    border: 0.125rem solid var(--gray-800);
    border-radius: 0.15rem;
    background-color: var(--gray-50);
    color: var(--white);
    margin-right: 1rem;
    position: relative;
  }
  input[type='checkbox']:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0.875rem;
    height: 0.875rem;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="white" data-slot="icon" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>');
    background-position: top left;
    transform: scale(0);
    transform-origin: bottom left;
    transition: all 0.1s ease-in-out;
  }
  input[type='checkbox']:checked {
    border-color: var(--blue-bright);
    background-color: var(--blue-bright);
  }
  input[type='checkbox']:checked::before {
    transform: scale(1);
  }
  input[type='checkbox']:hover {
    border-color: var(--blue-bright);
  }
  input[type='checkbox']:disabled {
    border-color: var(--gray-200);
    background-color: var(--gray-200);
  }

  input[type='radio'] {
    appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border: 0.125rem solid var(--gray-800);
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin-right: 1rem;
  }
  .checked input[type='radio'],
  input[type='radio']:hover {
    border-color: var(--blue-bright);
  }

  input[type='radio']:before {
    content: '';
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background-color: var(--blue-bright);
    transform: scale(0);
    transition: transform 0.1s ease-in-out;
  }

  input[type='radio'] {
    &.disabled::before {
      background-color: var(--gray-200);
    }
  }

  input[type='radio']:disabled {
    border-color: var(--gray-200);
    color: var(--gray-200);
  }
  .checked input[type='radio']::before {
    transform: scale(1);
  }

  .radio-list-item label {
    display: flex;
    align-items: center;
  }

  .radio-list-item {
    display: flex;
  }

  .check-box label {
    display: flex;
  }

  app-radio-list .task-item-label {
    margin-bottom: 0.5rem;
  }

  .date-picker-input-wrapper {
    input:focus {
      outline: none;
    }
  }

  .date-picker-input-wrapper {
    position: relative;
    box-shadow: 0 0 0 1px var(--default-border-color);
    border-radius: var(--default-radius);
    overflow: hidden;
    background-color: var(--white);

    input {
      width: 80%;
      font-size: 0.875rem;
      padding-left: 1rem;
      padding-right: 3rem;
      width: 100%;
      height: 2.5rem;
      z-index: 2;
      position: relative;
      background-color: transparent;
    }

    span.input-icon {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 1.125rem;
      z-index: 1;
      width: 100%;
      height: 100%;
      padding-right: 0.75rem;
    }
  }
}

.assign-trigger,
.reassign-trigger {
  border: none;
  font: 500 0.875rem/1rem var(--default-font);
  padding: 0;
  margin-left: 1rem;
  color: var(--blue-bright);

  &.disabled {
    color: var(--gray-400);
  }
}

app-text-box input.form-control {
  width: 66%;
}

.mat-mdc-text-field-wrapper {
  background-color: var(--white);
  border: 1px solid var(--default-border-color);
  border-radius: var(--default-radius) !important;
}

app-dropdown-box {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    min-height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .mat-mdc-select {
    line-height: 2.5rem;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.dropdown-box-panel
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  ) {
  background-color: var(--blue-bright);
}

app-file-attachment {
  .upload-text {
    font-size: 0.75rem;
    font-weight: 600;
  }

  .file-ext {
    text-transform: uppercase;
    font-size: 0.625rem;
  }

  .drop-zone-btn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    gap: 10px;
  }

  .drop-zone-copy {
    width: 85%;
    height: auto;
  }

  .upload-icon {
    font-size: 1.5rem;
  }

  .file-list {
    position: relative;
    border: 1px solid var(--gray-100);
    border-radius: var(--default-radius);
    max-height: 300px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .file {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      border-bottom: 1px solid var(--gray-100);

      &.deleting {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--gray-100);
          opacity: 0.25;
          z-index: 2;
        }

        .icon-button {
          animation: rotate 1s linear infinite;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.task-date-picker-dialog {
  border-radius: 0.625rem;
  overflow: hidden;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);

  .owl-dt-calendar-table .owl-dt-calendar-cell-content {
    border-radius: var(--default-radius);
  }

  .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    background-color: var(--blue-bright);
  }

  .owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
    border: 0.0625rem solid var(--blue-bright);
    color: var(--blue-bright);
  }

  .owl-dt-calendar-table .owl-dt-calendar-cell-out,
  .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after {
    opacity: 0;
    height: 0;
  }

  .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
    position: relative;

    span {
      opacity: 0;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--gray-400);
    }

    &[aria-label='Sunday']:before {
      content: 'S';
    }

    &[aria-label='Monday']:before {
      content: 'M';
    }

    &[aria-label='Tuesday']:before {
      content: 'T';
    }

    &[aria-label='Wednesday']:before {
      content: 'W';
    }

    &[aria-label='Thursday']:before {
      content: 'T';
    }

    &[aria-label='Friday']:before {
      content: 'F';
    }

    &[aria-label='Saturday']:before {
      content: 'S';
    }
  }

  .owl-dt-popup-container .owl-dt-calendar {
    height: auto;
  }

  .owl-dt-calendar-main {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 14px;
  }

  .owl-dt-calendar-control {
    padding-top: 25px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.global-component-spacing {
  margin-top: 14px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.global-component-shadow {
  // box-shadow: 0px 4px 6px 0px var(--gray-200);
  border: 1px solid var(--default-border-color);
  border-radius: var(--default-radius);
  background-color: var(--white);
  height: 100%;
  min-width: calc(454px + $table-margin);
}

.half-width {
  width: calc(50% - ($standard-gap * 0.5));
}

.global-page-full {
  display: flex;
  flex-direction: column;
  gap: $standard-margin;
  height: $view-box-height;
  width: 100%;
  overflow: hidden;
  .section {
    height: calc(($view-box-height) - $standard-gap - $better-header-height);
  }
}

.global-page-grow {
  display: flex;
  flex-direction: column;
  gap: $standard-margin;
  max-height: $view-box-height;
  width: 100%;
  .section {
    max-height: calc(($view-box-height) - $standard-gap - $better-header-height);
  }
}

.global-page-horizontal-split {
  display: flex;
  flex-direction: column;
  gap: $standard-margin;
  height: $view-box-height;

  width: 100%;
  .first-section {
    height: calc(
      ($view-box-height * 0.5) - $standard-gap - $better-header-height - ($standard-gap * 0.5)
    );
  }
  .section {
    height: calc(
      ($view-box-height * 0.5) - $standard-gap - $better-header-height - $nav-view-box-margin -
        ($standard-gap * 0.5)
    );
  }
}

.global-page-vertical-split {
  display: flex;
  gap: $standard-margin;
  height: $view-box-height;
  width: 100%;
  &.nested {
    width: 100%;
    height: 100%;
  }
}

.rotate {
  animation: rotate 2s linear infinite;
}

.mat-mdc-dialog-actions {
  &.global-modal-actions {
    justify-content: flex-end;
  }
}

.due-display {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.25rem;
}

.due-date-label-crossed {
  text-decoration: line-through;
}

.due-status {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
  padding: 2px 8px;
  color: var(--white);

  &.none {
    background-color: var(--green-bright);
  }

  &.red {
    background-color: var(--red-bright);
  }

  &.yellow {
    background-color: var(--yellow-bright);
  }

  &.green {
    background-color: var(--green-bright);
  }
}
.mat-mdc-snack-bar-container {
  .ng-star-inserted {
    padding: 0 0 0 8px;
  }
}

.better-table-header-title {
  font-family: var(--default-font);
  font-size: 24px;
  font-weight: 600;
  margin-top: $nav-view-box-margin;
  &.first {
    margin-top: 0;
  }
  &.vertical-center {
    margin-top: unset;
    display: flex;
  }
}

lucide-icon {
  &.disabled {
    color: var(--gray-300);
    cursor: not-allowed;
    pointer-events: none;
  }
}

.vertical-flex-center {
  margin-top: auto;
  margin-bottom: auto;
}

.heading-6 {
  //styleName: Heading 6;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}

// Global Classes
// Body Classes
.body-large-medium {
  font-size: 18px;
  font-weight: 500;
}

.body-medium-medium {
  font-size: 16px;
  font-weight: 500;
}

.body-medium-regular {
  font-size: 16px;
  font-weight: 400;
}

.body-small-medium {
  font-size: 14px;
  font-weight: 500;
}

.body-small-regular {
  font-size: 14px;
  font-weight: 400;
}

.body-large-semi {
  font-size: 18px;
  font-weight: 600;
}

.body-extra-small {
  font-weight: 600;
  font-size: 12px;
}

// Helper Classes
.default-border {
  border: 1px solid var(--default-border-color);
}

.dragon-drop-zone {
  -webkit-transition: height 0.4s ease;
  -moz-transition: height 0.4s ease;
  transition: height 0.4s ease;
  height: 1px;
  border-radius: var(--default-radius);

  &.title {
    height: 50px;
    background-color: var(--gray-200);
    padding: 16px;
  }
  &.sibling {
    margin-left: 10px;
  }
  &.available-zone {
    border: 1px solid var(--default-primary);
    height: 25px;
  }
  &.hovering {
    border: 1px solid var(--default-primary);
  }
  &.current-zone {
    height: 35px;
    background-color: var(--green-success-highlight);
    border: 1px solid var(--green-success);
  }
  &.primary-size {
    height: 10px;
  }
}

.default-button {
  margin-left: 8px;
  width: auto;
  height: 48px;
  display: flex;
  font-size: 16px;
  padding: 0 16px;
  justify-content: center;
  border-radius: var(--default-radius);
  box-shadow: 1px 1px 3px 0px var(--default-shadow-color);
  color: var(--default-primary);
  background-color: var(--white);
  &:hover {
    background-color: var(--default-primary);
    color: var(--white);
  }
  &:disabled {
    background-color: var(--gray-100);
    color: var(--gray-500);
    opacity: 10px;
    cursor: not-allowed;
    pointer-events: none;
  }
  cursor: pointer;
  .button-margins {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.is-disabled {
  color: var(--gray-500);
  cursor: not-allowed;
}

.default-button:hover .icon-circle {
  border-color: var(--white);
  color: var(--white);
}

.default-button:disabled .icon-circle {
  border-color: var(--gray-500);
  color: var(--gray-500);
}

.default-button.disabled {
  background-color: var(--gray-100);
  color: var(--gray-500);
  cursor: not-allowed;
}

.default-login-button {
  background-color: var(--default-primary);
  color: var(--white);
  width: 400px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: var(--default-radius);
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    background-color: var(--gray-175);
    color: var(--primary-text);
    opacity: 0.5;
  }
}

.subtext {
  color: var(--gray-600);
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  text-align: left;
}

.terlumina-button {
  padding: 12px, 16px, 12px, 16px;
  background-color: var(--default-primary);
  color: var(--white);
  border-color: var(--default-primary);
  width: 76px !important;
  height: 48px !important;
  font-size: 16px;
}

.terlumina-button.cancel {
  background-color: var(--gray-500);
  color: var(--white);
  border-color: var(--gray-500);
}

.terlumina-button.delete {
  background-color: var(--red-bright);
  color: var(--white);
  border-color: var(--red-bright);
}

.report-drag-preview {
  border: 1px solid var(--default-border-color);
  padding: 12px;
  background-color: var(--default-background-color);
  font-size: 18px;
  font-weight: 500;
  border-radius: var(--default-radius);
}

// Mat Select Styling
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 9px;
  margin-bottom: -23px;
}
.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  top: 50%;
}
.mdc-floating-label--float-above {
  top: 80% !important;
}

.cdk-global-overlay-wrapper {
  justify-content: center !important;
}

.mat-table {
  border-radius: var(--default-radius);
  overflow: hidden;
}

.background-white {
  background-color: var(--white) !important;
}

.search-icon-position {
  z-index: 10;
  margin-inline-end: 143px;
  position: absolute;
  top: 6px;
}

mat-dialog-actions {
  display: flex;
  gap: 8px;
}

a {
  &.a-link {
    color: var(--default-primary);
    cursor: pointer;
    &:hover {
      color: var(--default-primary-hover-bg);
    }
    &.disabled {
      pointer-events: none;
      color: var(--gray-400);
    }
  }
}
.type-dropdown {
  width: 250px;
  height: 48px;
  border: var(--default-border-color) 1px solid;
  border-radius: 6px;
}

.global-title {
  font-size: 28px;
  font-weight: 600;
  margin-right: 54px;
  margin-top: 8px;
}

.label {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.filter-container {
  width: 250px;
  height: 39.603px;
  padding: 12px 16px;

  border: 1px solid var(--default-border-color);
  border-radius: var(--default-radius);

  color: var(--black);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: left;

  cursor: pointer;

  span.truncate {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
  }

  &.disabled {
    background: var(--disabled);
    color: var(--gray-400);
    border: 1px solid var(--gray-400);
    cursor: none;
  }

  .container {
    margin-top: 0;
    text-overflow: ellipsis;
    width: 280px;
  }
}

.task-type-filter {
  color: var(--autocomplete-option-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  height: 38px;
}

.task-type-filter:hover {
  background-color: var(--blue-bright);
  color: var(--white);
  font-weight: 500;
  cursor: pointer;
}

.task-type-filter.current {
  font-weight: 500;
  color: var(--gray-50);
  background-color: var(--blue-bright);
}

.x-icon-wrapper {
  cursor: pointer;
  height: fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 50%;
  &:hover {
    background-color: var(--gray-50);
  }
  &.clicked {
    background-color: var(--gray-175);
  }
}

app-view-report,
app-view-table-report-task {
  .ag-root-wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .color-pill {
    border-radius: 10px;
    color: var(--white);
    text-align: center;
    height: 20px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    justify-content: center;
    &.red {
      background-color: var(--overdue-red);
    }
    &.yellow {
      background-color: var(--yellow-bright);
    }
    &.green {
      background-color: var(--green-success);
    }
  }
}
