/* stylelint-disable scss/at-rule-no-unknown */
/* 
  When adding variables to the theme, please use the following rules:
  - Use the -- prefix for all variables
  - Use the following naming convention for palette colors: --[color]-[shade] (e.g. --red-500)
  - Use the following naming convention for accent colors: --[context] (e.g. --red-800)
  - Use the following naming convention for status colors: --status-[context] (e.g. --blue-bright)
  - Use the following naming convention for state colors: --[context]-[state] (e.g. --red-button)
  - Use the following naming convention for context colors: --[context]-[property]--[state] (e.g. --gray-600 and --gray-800)
  - Always add any accent, status, state, or contextual colors to tailwind.config.js when adding them to the [theme].scss file
*/

@use 'sass:color';
@tailwind base;

@layer base {
  :root {
    //Palette
    --white-white: #f0f0f0;
    --blue-50: #f1f8fd;
    --operational-blue: #1c3fb7;
    --training-orange: #e1580e;

    --gray-50: #f5f5f5;
    --gray-100: #e8e8e8;
    --gray-150: #f9fafb;
    --gray-175: #e5e7eb;
    --gray-200: #d1d1d1;
    --gray-300: #b0b0b0;
    --gray-400: #888888;
    --gray-450: #717171;
    --gray-500: #6d6d6d;
    --gray-600: #5d5d5d;
    --gray-650: #52555a;
    --gray-800: #454545;
    --gray-925: #363636;
    --gray-950: #262626;

    --green-50: #f1fbea;
    --green-100: #def7d0;
    --green-dark: #398d18;
    --green-bright: #49aa23;

    // New Styles
    --default-border-color: #dfe4ea;
    --default-background-color: var(--gray-150);
    --default-text-color: #111928;
    --secondary-text-color: var(--gray-650);
    --default-primary: #3758f9;
    --default-primary-hover-bg: #1b44c8;
    --default-secondary-hover-bg: #e1e8ff;
    --default-secondary-active-bg: #adbcf2;
    --default-tertiary-hover-bg: #f3f4f6;
    --default-gray: #6b7280;
    --default-shadow-color: #a6afc366;
    --default-box-shadow: 0px 1px 3px 0px #a6afc366;
    --default-radius: 6px;
    --default-unselected: #d1d5db;
    --default-midnight: #374151;
    --default-disabled: var(--gray-175);
    --yellow-dark: #dc8911;
    --yellow-bright: #efa131;
    --yellow-light: #fcd1963f;
    --yellow-scale: #fbbf24;
    --yellow-shade: #fff6de;
    --red-50: #fff0f2;
    --red-dark: #a01d20;
    --red-bright: #cf272a;
    --red-800: #af041d;
    --red-button: #910b1f;
    --color-scale-red: #f23030;
    --blue-bright: var(--default-primary);
    --blue-dark: #2d4c7b;
    --white: #ffffff;
    --black: #000000;
    --gray-900: #333333;
    --green-success: #429b1f;
    --default-green: #22ad5c;
    --dark-green: #1a8245;
    --light-green: #daf8e6;
    --light-gray: #8899a8;
    --green-success-highlight: rgba(66, 155, 31, 0.2);
    --primary-highlight: rgba(55, 88, 249, 0.6);
    --nav-highlight: rgba(55, 88, 249, 0.05);
    --primary-ripple: rgba(55, 88, 249, 0.1);
    --default-highlight-color: rgba(55, 88, 249, 0.05);
    --tab-highlight: rgba(67, 97, 255, 0.1);
    --tab-inactive: rgba(249, 250, 251, 1);
    --terlumina-orange: #e9901e;
    --terlumina-blue: #386082;
    --primary-text: #111928;
    --dark-blue: #111928;
    --tooltip-background-color: #4b5563;

    --overdue-red: #c22528;

    --mobile-text-light: #4e4e4e;
    --mobile-header-bg-color: #1f2a37;
    --mobile-underline: #3871c7;
    --mobile-text: #111111;
    --mobile-dark-red: #e10e0e;
    --mobile-light-red: #fef3f3;
    --mobile-progress-track: #fff0e9;
    --mobile-warn-orange: #f27430;
    --mobile-orange-light: #fcd1963f;
    --mobile-radio-color: #49454f;
    --mobile-border-color: #edeff1;
    --mobile-background-color: #5e79fa;
    --mobile-focus-color: #3850c7;

    //Font
    --default-font: 'Inter', sans-serif;

    //Contextual
    --login-input-bg--focused: transparent;
    --table-edit-button-bg: transparent;
    --time-select-am-bg: transparent;
  }
}
